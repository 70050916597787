import React from 'react';
import HeroSection from './component/HeroSection';
import Service from './Services';
import Contact from './Contact'

const Home = () => {

  const data = {
    subhead: "Hey There ! I'm ",
    name: "Venkatesh Moyya",
    myRole: "FullStack Developer",
    myInfo: (
      <p>
        A Fullstack Developer and Technical Trainer with expertise in the <strong>MERN stack</strong> (MongoDB, Express.js, React.js, Node.js) and Python. Over the last two years, I have successfully trained over <strong>1000 students</strong>, helping them transition from learners to industry-ready professionals.
        <br /><br />
        I specialize in creating scalable applications and delivering <strong>project-based learning</strong> to simplify complex technologies. I have collaborated with companies to provide industry-aligned training in web development.
      </p>
    ),  image: "../images/profile.jpg",
  }
  return (
    <>
      <HeroSection { ...data }/>
      <Service />
      <Contact />
    </>
  )
}

export default Home
