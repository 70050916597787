import React from 'react';
import HeroSection from './component/HeroSection';
import Educations from './component/Educations'
import Skills from './component/Skills'

const About = () => {

  const data = {
    subhead: "WHO I AM",
    name: "Venkatesh Moyya",
    image: "./images/about.jpg",
   myInfo: (
      <p>
        I am a passionate <strong>Fullstack Developer and Technical Trainer</strong>, leveraging deep expertise in the <strong>MERN stack</strong> (MongoDB, Express.js, React.js, Node.js) and Python to build high-quality applications and mentor the next generation of developers.
        <br /><br />
        With over <strong>2 years of experience</strong> as a freelance educator, I have trained <strong>1000+ students</strong> in full-stack web development, equipping them with hands-on coding skills and practical knowledge.
        <br /><br />
        <strong>Industry Collaborations:</strong> I have partnered with companies like Coign, Six Phrase, Talentio, CodeNow, IEXCEL Learning Solutions, and Vegaahi IT Pvt Ltd to deliver customized training programs.
        <br /><br />
        <strong>Expertise:</strong> React.js, Node.js, MongoDB, Python, scalable application development, and modern web technologies.
      </p>
    ), }

  return (
    <>
      <HeroSection {...data} />
      <div className="about-section">
        <div className="common-heading">
          <p className='common-subheading mb-2'>My Educations & Skills</p>
          <h1>More About Me</h1>
        </div>

        <div className="card-container grid grid-two-column ">
          <Educations />
          <Skills />
        </div>
      </div>
    </>
  )
}

export default About
